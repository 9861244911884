.goldStockDetailsContainer {
  cursor: pointer;
  width: 1160px;
  margin: 0 auto;
}

.content {
  background: url(../../../assets/imgs/report/bg.png) no-repeat;
  background-size: 100%;
  min-height: 50vh;
  .content_bg {
    background: linear-gradient(to bottom, #e5f1ff 0%, transparent 20%);
    .content_box {
      width: 1160px;
      margin: 0 auto;
      padding-top: 20px;
    }
  }
}

.bread {
  background: #fff;
  padding: 0 30px;
  height: 54px;
  display: flex;
  align-items: center;
  font-size: 15px;
  color: #000;
}

.link {
  color: #000;
  margin: 0 14px;
}

.tableBox {
  margin-top: 20px;
}

.redFont {
  font-size: 16px;
  color: #fd0000;
}

.greenFont {
  font-size: 16px;
  color: #02c40a;
}

.greyFont {
  font-size: 16px;
  color: #585654;
}
