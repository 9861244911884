.goldStockPage {
  cursor: pointer;
}
.top {
  background: #f4f5f7;
  border-bottom: 1px solid #cdced0;
  position: sticky;
  top: 68px;
  left: 0;
  z-index: 100;
}
.contarin {
  width: 1160px;
  margin: 0 auto;
  height: 56px;
  ul {
    padding: 0 60px;
    display: flex;
    align-items: center;
    li {
      line-height: 56px;
      margin-right: 81px;
      cursor: pointer;
      font-size: 17px;
      color: #585654;
      position: relative;
      &.active {
        color: #3c81ce;
        &:after {
          content: ' ';
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 2px;
          background: #1769c5;
        }
      }
    }
  }
}

.content {
  background: url(../../../assets/imgs/report/bg.png) no-repeat;
  background-size: 100%;
  min-height: 50vh;
  .content_bg {
    background: linear-gradient(to bottom, #e5f1ff 0%, transparent 20%);
    .content_box {
      width: 1160px;
      margin: 0 auto;
      padding-top: 20px;
    }
  }
}

.goldStockBigData {
  width: 1160px;
  margin: 0 auto;
  background: #fff;
  margin-bottom: 20px;
}

.commonTitle {
  border-bottom: 1px solid #cdced0;
  padding-left: 30px;
  padding-right: 30px;
  color: #000;
  font-size: 20px;
  height: 50px;
  line-height: 50px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  span {
    font-size: 15px;
    color: #585654;
    margin-left: 15px;
  }
}

.goldStockTable {
  .ant-table-thead {
    th {
      background: #f1f8ff !important;
    }
  }
}

.threeArea {
  display: flex;
  margin-bottom: 60px;
  padding: 20px 0;
  background: #fff;

  .industryHot {
    flex: 1;
    border-right: 1px solid #cdced0;
    .nameAndHot {
      padding: 0 30px;
      display: flex;
      color: #585654;
      font-size: 17px;
      height: 60px;
      align-items: center;
      .name {
        // width: 20%;
        margin-right: 10px;
      }
    }
    .barChart {
      width: 100%;
      height: 300px;
    }
    .industryHotTitle {
      padding-left: 30px;
      display: flex;
      align-items: center;
      .name1 {
        font-size: 16px;
        color: #000;
        // font-weight: bold;
        margin-right: 10px;
      }
      .name2 {
        font-size: 14px;
        color: #585654;
        margin-right: 20px;
      }
    }
  }
  .industryDistributed {
    padding: 0 30px;
    flex: 2;
    background: #fff;
    .industryDistributedTitle {
      .name1 {
        font-size: 16px;
        // font-weight: bold;
        color: #000;
        margin-right: 10px;
      }
      .name2 {
        font-size: 14px;
        color: #585654;
        margin-right: 20px;
      }
    }
  }
  .goldStockBigData {
    flex: 1;
    background: #fff;
    .goldStockMonthData {
      padding: 20px 30px;
      ul {
        display: flex;
        justify-content: space-around;
        color: #585654;
        li {
          font-size: 20px;
          text-align: center;
          span {
            color: #49a3e3;
          }
          .desc {
            font-size: 17px;
          }
        }
      }
    }
    .ant-tabs-nav-list {
      left: 30px;
    }
  }
}

.recommendList {
  display: flex;
  margin: 0 30px;
  padding: 20px 0;
  border-bottom: 1px solid #cdced0;
  .left {
    flex: 1;
    text-align: center;
    border-right: 1px solid #cdced0;
    font-size: 15px;
    color: #585654;
    span {
      color: #49a3e3;
      font-size: 20px;
    }
    div:first-child {
      font-size: 20px;
      margin-bottom: 6px;
    }
    div:last-child {
      font-size: 15px;
    }
  }
  .right {
    flex: 2;
    .stockList {
      color: #585654;
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
    }
    .open {
      text-align: center;
    }
  }
}

.hotGoldItem:last-child {
  .hotGoldItemContent {
    border-bottom: none !important;
  }
}

.weekPriceLevel {
  display: flex;
  align-items: center;
  .barBg {
    width: 116px;
    height: 15px;
    border: 1px solid #d9e7f3;
    position: relative;
    .bar {
      background: linear-gradient(90deg, #49a3e3 0%, #317acc 100%);
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 999;
    }
  }
  .weekPriceLevelValue {
    padding-left: 12px;
    color: #585654;
    // font-size: 17px;
  }
}

.stockName {
  color: #585654;
  // font-size: 17px;
  span {
    padding-left: 6px;
  }
}

.recommendList:last-child {
  border-bottom: none;
}

.downArrows {
  margin-left: 24px;
  color: #49a3e3 !important;
}

.upArrows {
  margin-left: 6px;
  color: #e8281b !important;
}

.chooseYear {
  margin-right: 10px;
}

.BigDataTitle {
  display: flex;
  justify-content: space-between;
  padding: 0 30px;
  > div {
    flex: 1;
    font-size: 20px;
    font-weight: bold;
    color: #000;
    height: 90px;
    display: flex;
    align-items: center;
    span {
      color: #00a6e9;
      font-size: 20px;
      margin-left: 10px;
      margin-right: 10px;
    }
  }
}

.changeTab {
  padding: 0 30px;
  display: flex;
  padding-bottom: 20px;
  div {
    width: 160px;
    height: 46px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    background: #f4f5f7;
    font-size: 18px;
    color: #585654;
    &.active {
      background: #00a6e9;
      color: #fff;
    }
  }
}

.goldStockDetails {
  // padding: 0 30px;
  padding-right: 30px;
  display: flex;
  padding-bottom: 20px;
  & > div {
    width: 145px;
    border-right: 1px solid #cdced0;
  }
  .descTitle {
    font-size: 16px;
    color: #585654;
  }
  .biaodi {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    span {
      font-size: 26px;
      color: #fd0000;
    }
  }
  .zhangfu {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    span {
      font-size: 26px;
      color: #e8281b;
    }
  }
  .yearZhangfu {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    span {
      font-size: 26px;
      color: #02c40a;
    }
  }
  .hangye {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    span {
      font-size: 26px;
      color: #333;
    }
  }
  .biaodiDesc {
    font-size: 16px;
    color: #585654;
  }
  .stockList {
    padding: 0 20px;
    flex: 1;
    border-right: none;
    span {
      margin-right: 10px;
    }
  }
}

.brokerageAndGoldStock {
  background: #fff;
  margin-bottom: 20px;
  .right {
    // flex: 1;
    display: flex;
    align-items: center;
    .chooseMonth2 {
      height: 32px;
    }
    .chooseSearch {
      width: 210px;
      height: 32px;
      margin-left: 0px;
      margin-right: 14px;
    }
    .ant-input-group-addon {
      span {
        margin-left: 0;
        color: #fff !important;
      }
      button {
        background: #00a6e9;
        border-color: #00a6e9;
      }
    }
  }
  tr th {
    background: #f1f8ff;
    border-bottom: none;
    font-size: 16px;
    color: #585654;
    &:first-child {
      padding-left: 30px;
    }
    &::before {
      content: none !important;
    }
  }
  tr td {
    &:first-child {
      padding-left: 30px;
    }
  }
}

.lookMoreDetails {
  // color: #3c81ce;
  font-size: 16px;
}

.brokerageIndustryStatistics {
  background: #fff;
  .commonTitle {
    span {
      // margin-left: 16px;
    }
  }
}

.moreRecommend {
  padding: 0 30px;
  padding-bottom: 20px;
  .moreRecommendItem {
    display: flex;
    .left {
      width: 240px;
      color: #585654;
      font-size: 18px;
      margin-right: 38px;
      span {
        font-size: 18px;
        color: #00a6e9;
        margin: 0 5px;
      }
    }
    .right {
      flex: 1;
      span {
        color: #333;
        font-size: 18px;
        margin-right: 16px;
      }
    }
  }
}

.fontSize16 {
  font-size: 16px;
}

.combinationName {
  font-size: 16px;
  color: #585654;
}

.redFont {
  font-size: 16px;
  color: #fd0000;
}

.greenFont {
  font-size: 16px;
  color: #02c40a;
}

.greyFont {
  font-size: 16px;
  color: #585654;
}
