.events {
  cursor: pointer;
}

.content {
  background: url(../../../assets/imgs/report/bg.png) no-repeat;
  background-size: 100%;
  min-height: 50vh;
  .content_bg {
    background: linear-gradient(to bottom, #e5f1ff 0%, transparent 20%);
    .content_box {
      width: 1160px;
      margin: 0 auto;
      padding-top: 20px;
    }
  }
}

.eventsSearch {
  display: flex;
  justify-content: center;
  align-items: center;
  .name {
    font-size: 17px;
    color: #000;
    margin-right: 20px;
  }
  .ipt {
    // flex: 1;
    width: 600px;
    display: flex;
    p {
      input {
        width: 100%;
        height: 100%;
        border: none;
        padding: 0 10px;
        outline: none;
      }
      &:first-child {
        flex: 1;
        height: 47px;
        background: #f7fafd;
        // border: 1px solid #585654;
      }
      &:last-child {
        width: 130px;
        height: 47px;
        background: url(../../../assets/imgs/report/search.png) no-repeat;
        background-size: 100% 100%;
        border-radius: 0px 3px 3px 0px;
        &:active {
          background: url(../../../assets/imgs/report/search_a.png) no-repeat;
          background-size: 100% 100%;
        }
      }
    }
  }
}

.plate {
  display: flex;
  width: 1160px;
  justify-content: space-around;
  margin-top: 20px;
  margin-bottom: 20px;
  .plate_item {
    // flex: 1;
    width: 380px;
    padding: 20px 40px;
    color: #fff;
    &.red {
      background: #da7567;
    }
    &.green {
      background: #3e9781;
    }
    &.grey {
      background: #b2b2b2;
    }
    .item_title {
      display: flex;
      justify-content: space-between;
      .left {
        font-weight: bold;
      }
    }
    .item_content {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      margin: 20px 0;
    }
    .item_stock_list {
      display: flex;
      .stock_item {
        font-size: 15px;
        color: #fefeff;
        // height: 22px;
        padding: 2px;
        background: rgba(255, 255, 255, 0.3);
        margin-right: 14px;
      }
    }
  }
}

.tableFliter {
  display: flex;
  justify-content: space-between;
  .left {
    display: flex;
    .monthItem {
      width: 160px;
      height: 40px;
      background: #fff;
      color: #000;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 12px;
      &.active {
        background: #00a6e9;
        color: #fff;
      }
    }
  }
  .timeChange {
    height: 40px;
    width: 200px;
  }
}

.eventsName {
  font-size: 19px;
  color: #000;
  width: 500px;
}

.time {
  font-size: 13px;
  color: #585654;
  width: 90px;
}

.desc {
  font-size: 15px;
  color: #585654;
}
