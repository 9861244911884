.aboutUsTitle {
  width: 100%;
  height: 246px;
  background: url(../../assets//imgs/aboutUs/banner.png) no-repeat;
  text-align: center;
  padding-top: 58px;
  color: #fff;
  font-size: 45px;
  font-weight: bold;
}

.textContent {
  text-align: center;
  color: #000;
  font-size: 20px;
  padding-top: 60px;
  font-weight: bold;
}

.companyIntro {
  margin-bottom: 40px;
}

.pic {
  width: 685px;
  height: auto;
  margin-top: 30px;
  margin-bottom: 80px;
}
