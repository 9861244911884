.contactUs {
  width: 1160px;
  margin: 0 auto;
}

.contactUsContent {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
  .left {
    flex: 1;
  }

  .right {
    flex: 1;
    position: relative;
    .map {
      width: 800px;
      height: 400px;
    }
    .star {
      position: absolute;
      right: 197px;
      top: 176px;
      z-index: 99;
    }
  }
}

h2 {
  margin-top: 56px;
  margin-bottom: 26px;
  text-align: center;
}

.circle1,
.circle2,
.circle3 {
  position: absolute;
  right: 202px;
  top: 181px;
  z-index: 9;
  width: 10px;
  height: 10px;
  background: #1e85d2;
  border: 1px solid #1e85d2;
  border-radius: 999px;
}
.circle1,
.circle2,
.circle3 {
  /* animation: circleChange 2s 1s ease-out infinite; */
  animation-name: circleChange;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.circle1 {
  animation-delay: 1s;
}
.circle2 {
  animation-delay: 2s;
}
.circle3 {
  animation-delay: 3s;
}

@keyframes circleChange {
  0% {
    transform: scale(1);
    opacity: 0.95;
  }
  25% {
    transform: scale(2);
    opacity: 0.75;
  }
  50% {
    transform: scale(3);
    opacity: 0.5;
  }
  75% {
    transform: scale(4);
    opacity: 0.25;
  }
  100% {
    transform: scale(5);
    opacity: 0.05;
  }
}
