.box {
  background: #f4f5f7;
  min-height: 100vh;
  font-size: 17px;
  margin-bottom: 27px;
}

.top {
  background: #f4f5f7;
  border-bottom: 1px solid #cdced0;
  position: sticky;
  top: 68px;
  left: 0;
  z-index: 100;
}
.contarin {
  width: 1160px;
  margin: 0 auto;
  height: 56px;
  ul {
    padding: 0 60px;
    display: flex;
    align-items: center;
    li {
      line-height: 56px;
      margin-right: 81px;
      cursor: pointer;
      font-size: 17px;
      color: #585654;
      position: relative;
      &.active {
        color: #3c81ce;
        &:after {
          content: " ";
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 2px;
          background: #1769c5;
        }
      }
    }
  }
}
.text-content {
  display: flex;
  min-height: 90vh;
  margin-top: 22px;
  img {
    max-width: 100%;
  }
  .text {
    padding: 30px 35px;
    flex: 1;
    font-size: 18px;
    background: #fff;
    .title {
      // width: 584px;
      font-size: 24px;
      //font-weight: 400;
      line-height: 42px;
      margin-bottom: 10px;
    }
    .tag {
      display: flex;
      align-items: center;
      justify-content: flex-start; //space-between;
      color: #929598;
      font-size: 14px;
      color: rgb(153, 153, 153);
      overflow: hidden;
      .resource {
        display: flex;
        align-items: center;
        section {
          margin-left: 20px;
          width: 110px;
          line-height: 21px;
          text-align: center;
          font-size: 14px;
          color: #387bf6;
          border: 1px solid #387bf6;
          border-radius: 4px;
        }
      }
      .createUser {
        margin-left: 20px;
        display: flex;
        align-items: center;
        img {
          margin-right: 5px;
        }
      }
      .stock {
        margin-left: 20px;
      }
      // &:after {
      //   content: "";
      //   width: 90px;
      // }
    }
    .time1 {
      font-size: 14px;
      color: #929598;
      margin: 13px 0 13px 0;
    }
    .pdf {
      display: flex;
      font-size: 14px;
      align-items: center;
      color: #3c81ce;
      margin-bottom: 1px;
      img {
        margin-right: 4px;
      }
    }
    .innerhtml {
      padding: 1px 0;
      font-size: 16px;
      //font-weight: 400;
      color: rgb(51, 51, 51);
      line-height: 36px;
    }
    .ipyGSt {
      font-size: 20px;
      color: rgb(51, 51, 51);
      line-height: 36px;
      margin-top: 1px;
      margin-buttom: 2px;
      padding-left: 1px;
      font-weight: bold;
      position: relative;
    }
  }
  .recommand {
    width: 381px;
    margin-left: 20px;
    .item {
      margin-bottom: 21px;
      background: #fff;
      padding: 0 27px;
      .item_label {
        line-height: 75px;
        border-bottom: 1px solid #e9e9e9;
        font-size: 20px;
        font-weight: bold;
      }
    }
    .item_list {
      padding: 26px 0;
      font-size: 16px;
      font-weight: 400;
      color: #585654;
      line-height: 30px;
      display: flex;
      flex-wrap: wrap;
      p {
        width: calc(100% / 3);
        &:nth-of-type(3n - 1) {
          text-align: center;
        }
        &:nth-of-type(3n) {
          text-align: right;
        }
        &.active {
          color: #3c81ce;
          &:after {
            content: " ";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 2px;
            background: #1769c5;
          }
        }
      }
    }
    .item_column {
      padding: 26px 0;
      font-size: 17px;
      font-weight: 300;
      line-height: 22px;
      p {
        margin-bottom: 29px;
        overflow: hidden;
        display: -webkit-box; //将元素设为盒子伸缩模型显示
        -webkit-box-orient: vertical; //伸缩方向设为垂直方向
        -webkit-line-clamp: 2; //超出3行隐藏，并显示省略号
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
