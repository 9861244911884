.box {
  background: #f4f5f7;
  min-height: 100vh;
  font-size: 17px;
}


.top {
  background: #f4f5f7;
  border-bottom: 1px solid #cdced0;
  position: sticky;
  top: 68px;
  left: 0;
  z-index: 100;
}
.contarin {
  
  width: 1400px;
  margin: 0 auto;
  height: 56px;
  ul {
    padding: 0 60px;
    display: flex;
    align-items: center;
    li {
      line-height: 56px;
      margin-right: 81px;
      cursor: pointer;
      font-size: 17px;
      color: #585654;
      position: relative;
      &.active {
        color: #3c81ce;
        &:after {
          content: " ";
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 2px;
          background: #1769c5;
        }
      }
    }
  }
}
.list {
  width: 1400px;
  margin: 0 auto;
  display: flex;
  background: url(../../../assets/imgs/report/bg.png) no-repeat;
  background-size: 100%;
  min-height: 50vh;
  .link{
    font-size: 16px;
      color: #333333;
      font-weight: bold
    }
    
  .list_content {
    flex: 1;
    //margin-left: 20px;
    width: 1160px;
    margin: 0 auto;
    /* padding-top: 83px;*/
    .reportList {
      padding: 28px 70px;
      background: #fff;
      ul {
        color: #585654;
        li {
          display: inline-block;
          cursor: pointer;
          margin: 0 17px;
          position: relative;
          &:after {
            content: " ";
            position: absolute;
            height: 17px;
            width: 1px;
            right: -18px;
            top: 50%;
            transform: translateY(-50%);
            background: #585654;
          }
          &:last-child {
            &:after {
              display: none;
            }
          }
          &.active {
            &:before {
              content: " ";
              position: absolute;
              width: 100%;
              height: 1px;
              background: #585654;
              bottom: 0;
              left: 0;
            }
          }
        }
      }
    }
    .title {
      margin: 22px;
    }
    .search {
      padding: 31px 30px;
      background: #fff;
      .search_ipt {
        display: flex;
        cursor: pointer;
        align-items: center;
        ._ipt {
          flex: 1;
          display: flex;
          p {
            input {
              width: 100%;
              height: 100%;
              border: none;
              padding: 0 10px;
              outline: none;
            }
            &:first-child {
              flex: 1;
              height: 47px;
              background: #f7fafd;
              border: 1px solid #585654;
            }
            &:last-child {
              width: 130px;
              height: 47px;
              background: url(../../../assets/imgs/report/search.png) no-repeat;
              background-size: 100% 100%;
              border-radius: 0px 3px 3px 0px;
              &:active {
                background: url(../../../assets/imgs/report/search_a.png)
                  no-repeat;
                background-size: 100% 100%;
              }
            }
          }
        }
        ._iptdate {
          flex: 1;
          display: flex;
          p {
            div {
              width: 100%;
              height: 100%;
              border: none;
              padding: 0 10px;
              outline: none;
            }
            &:first-child {
              flex: 1;
              height: 47px;
              background: #f7fafd;
              border: 1px solid #585654;
            }
            &:last-child {
              width: 130px;
              height: 47px;
              background: url(../../../assets/imgs/report/search.png) no-repeat;
              background-size: 100% 100%;
              border-radius: 0px 3px 3px 0px;
              &:active {
                background: url(../../../assets/imgs/report/search_a.png)
                  no-repeat;
                background-size: 100% 100%;
              }
            }
          }
        }
        ._sbtn {
          &:active {
            box-shadow: 0 0 5px #aaa;
          }
          width: 71px;
          margin-left: 22px;
          line-height: 47px;
          color: #ffffff;
          text-align: center;
          background: linear-gradient(90deg, #49a3e3 0%, #317acc 100%);
          border-radius: 0px 3px 3px 0px;
          border: 1px solid #49a3e3;
        }
        ._btn {
          &:active {
            box-shadow: 0 0 5px #aaa;
          }
          width: 151px;
          margin-left: 22px;
          line-height: 47px;
          color: #ffffff;
          text-align: center;
          background: linear-gradient(90deg, #49a3e3 0%, #317acc 100%);
          border-radius: 0px 3px 3px 0px;
          border: 1px solid #49a3e3;
        }
      }
      .search_list {
        margin-top: 29px;
        .ant-radio-wrapper {
          color: #3c81ce;
        }
      }
    }
    .table {
      margin-bottom: 30px;
      li {
        cursor: pointer;
        height: 80px;
        background: #ffffff;
        padding: 13px 22px;
        margin-top: 1px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        &:hover {
          box-shadow: 0 0 10px #ccc;
        }
        a {
          font-size: 15px;
          color: #585654;
          &:first-child {
            font-size: 16px;
            color: rgb(51, 51, 51);
            font-weight: bold;
          }
          &:last-child {
            span {
              &:first-child {
                margin-right: 46px;
              }
            }
          }
        }
        p {
          font-size: 12px;
          color: rgb(153, 153, 153);
          &:first-child {
            font-size: 16px;
            color: rgb(51, 51, 51);
            font-weight: bold;
          }
          &:last-child {
            span {
              &:first-child {
                margin-right: 46px;
              }
            }
          }
        }
      }
    }
  }
  .recommand {
    flex: 1;
    flex: 0 0 15%;
    margin-left: 20px;
    .item {
      margin-bottom: 21px;
      background: #fff;
      padding: 0 27px;
      .item_label {
        line-height: 75px;
        border-bottom: 1px solid #e9e9e9;
        font-size: 20px;
        font-weight: bold;
      }
    }
    .item_list {
      padding: 16px 0;
      font-size: 16px;
      font-weight: 400;
      color: #585654;
      line-height: 30px;
      display: flex;
      flex-wrap: wrap;
      p {
        width: 90%;
        text-align: center;
        &.active {
          color: #3c81ce;
          &:after {
            content: " ";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 2px;
            background: #1769c5;
          }
        }
      }
    }
    .item_column {
      padding: 26px 0;
      font-size: 17px;
      font-weight: 300;
      line-height: 22px;
      p {
        margin-bottom: 29px;
        overflow: hidden;
        display: -webkit-box; //将元素设为盒子伸缩模型显示
        -webkit-box-orient: vertical; //伸缩方向设为垂直方向
        -webkit-line-clamp: 2; //超出3行隐藏，并显示省略号
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
